import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import Panel from '../../../core/Panel';

import './index.css';

export class Error404Page extends Component {
  static propTypes = {};

  render() {
    return (
      <App displayGettingStartedPanel={false}>
        <div className="component error-404-page">
          <Helmet title="Page not found – ThinkReservations">
            <meta
              name="description"
              content="We're sorry. The page you requested could not be found."
            />
          </Helmet>

          <Panel>
            <h1>Page not found</h1>
            <p>We're sorry. The page you requested could not be found.</p>
          </Panel>
        </div>
      </App>
    );
  }
}

export default Error404Page;
