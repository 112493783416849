import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class Panel extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    backgroundColor: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    backgroundColor: '#ffffff',
  };

  render() {
    const { id, className, backgroundColor } = this.props;

    const componentStyles = {
      backgroundColor: backgroundColor,
    };

    return (
      <div
        className={`component panel ${className}`}
        id={id}
        style={componentStyles}
      >
        <div className="panel-inner-wrapper">{this.props.children}</div>
      </div>
    );
  }
}

export default Panel;
